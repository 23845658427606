import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API getDaftarPegawaiST */
export function getDaftarPegawaiSTALL(id_letter) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/m/getData?datasurat=' + id_letter
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDaftarPegawaiSTApproved(id_letter) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/m/getData?datasurat=' + id_letter + '&eostatus=22'
  var data = ''
  console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getDaftarPegawaiSTByUserPosAllStatus(userpositions_pegawai, MYStart, MYEnd) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists?hruserpositions_pegawai=' + userpositions_pegawai + '&dateStart_contains=' + MYStart + '&dateEnd_contains=' + MYEnd 
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDaftarPegawaiSTByUserPos(userpositions_pegawai, MYStart, MYEnd) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists?hruserpositions_pegawai=' + userpositions_pegawai + '&dateStart_contains=' + MYStart + '&dateEnd_contains=' + MYEnd + '&eostatus=22' //approve
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDaftarSTFinalCurrYear() {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var d = new Date();
  var year = d.getFullYear();

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists?thang=' + year + '&eostatus_general=21' //approve
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDaftarNamaByID(id) {
  // console.log(id)
  if (id <= 0) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/m/byid/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertDaftarPegawaiST(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/m/dscr'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

export function deleteDaftarPegawaiST(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function declineDaftarPegawaiST(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var currrole = secureStorage.getItem('currRole')
  var myuserpos = currrole.id
  var data = {
    eostatus: 6,
    hruserpositions_approver : myuserpos,
    isRemoved:1
  }

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateDaftarPegawaiST(data, id) {
  // console.log('updateDaftarPegawaiST')
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  if (!data) return null
  // console.log(data)
  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/m/dsupd/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function resetPegawaiSTbyDatasurat(id_letter) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/daftarpegawaists/reset/peg'
  var data = {
    idsurt: id_letter
  }
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API getDaftarPegawaiST ==== */

